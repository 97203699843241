import styled from 'styled-components';

export const PendingFilesContainer = styled.div.attrs(props => ({
	backgroundColor: props.backgroundColor || props.theme.white
}))`
	background-color: ${props => props.backgroundColor};
	text-align: right;
`;

export const ManualUpload = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px;
`;

export const Heading = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const ButtonContainer = styled.div`
	white-space: nowrap;
`; 