import React from 'react';

export default function Close(props) {

	return (
		<svg width="23" height="23" viewBox="-2 -2 24 24">
			<path
				fill="transparent"
			    strokeWidth="3"
			    stroke={props.stroke}
			    strokeLinecap="round"
				d="M 3 16.5 L 17 2.5"
			/>
			<path
				fill="transparent"
				strokeWidth="3"
				stroke={props.stroke}
				strokeLinecap="round"
				d="M 3 2.5 L 17 16.346"
			/>
		</svg>
	);
}