const container = {
	variants: {
		show: {
			x: 0,
			opacity: 1,
			transition: {
				x: {
					stiffness: 1000,
					velocity: -100
				}
			}
		},
		hide: {
			x: 100,
			opacity: 0,
			transition: {
				x: {
					stiffness: 1000
				}
			}
		}
	}
};

const deleteIconActions = css => ({
	backgroundColor: css.danger,
	scale: 0.7,
	transition: {
		duration: 0.1
	}
});

export {
	container,
	deleteIconActions
};