const humanReadableSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const rawFileSize = size => {
	if (typeof size === 'string') {
		const noSpaces = size.replace(/,|\s+/g, '');
		const number = noSpaces.match(/[a-z]+|[^a-z]+/gi);
		const humanReadable = number[1] && number[1].toLowerCase();

		if (['b', 'kb', 'mb', 'gb'].includes(humanReadable)) {

			switch(humanReadable) {
				case 'gb':
					return number[0] * Math.pow(1024, 3);
				case 'mb':
					return number[0] * Math.pow(1024, 2);
				case 'kb':
					return number[0] * Math.pow(1024, 1);
				default:
					return number[0] * Math.pow(1024, 0);
			}
		} else if ((+number[0]) !== NaN) {
			return +number[0];
		}
	} else if (typeof size === 'number') {
		return size;
	}

};

export {
	humanReadableSize,
	rawFileSize
};