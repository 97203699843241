import React, { useState, useEffect, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import {
	Modal
} from 'reactstrap';

import useWindowSize from '../../useWindowSize';

import Close from '../../Svgs/Close';
import FrameCorner from '../../Svgs/FrameCorner';

import {
	CameraButton,
	CameraButtonPress,
	CloseCamera
} from './Styles';

import './camera.css';

const cameraQualityAttributes = {
	FHD: {
		width: 1920,
		height: 1080
	},
	'2K': {
		width: 2560,
		height: 1440
	},
	'4K': {
		width: 3840,
		height: 2160
	}
};

const OpenCamera = ({css, isOpen, closeModal, cameraQuality, handleAdd, handleFallback}) => {

	const [open, setOpen] = useState(isOpen);
	const [showAlignmentGrid, setShowAlignmentGrid] = useState(false);
	const [enableCameraButton, setEnableCameraButton] = useState(true);
	const [cameraDimensions, setCameraDimensions] = useState({width: null, height: null});
	const [screenAspectRatio, setScreenAspectRatio] = useState(0);
	const [isLandscape, setIsLandscape] = useState(false);

	const windowSize = useWindowSize();

	const videoConstraints = {
		...cameraQualityAttributes[cameraQuality],
		facingMode: 'environment'
	};

	const webcamRef = useRef(null);

	const calculateCameraViewWidth = () => {
		let cameraAspectRatio;
		if (isLandscape) {
			cameraAspectRatio = cameraDimensions.width / cameraDimensions.height;
			return (screenAspectRatio < cameraAspectRatio ? windowSize.width : cameraDimensions.width / cameraDimensions.height * windowSize.height);
		} else {
			cameraAspectRatio = cameraDimensions.height / cameraDimensions.width;
			return (screenAspectRatio < cameraAspectRatio ? windowSize.width : cameraDimensions.height / cameraDimensions.width * windowSize.height);
		}		
	}

	const calculateCameraViewHeight = () => {
		let cameraAspectRatio;
		if (isLandscape) {
			cameraAspectRatio = cameraDimensions.width / cameraDimensions.height;
			return (screenAspectRatio > cameraAspectRatio ? windowSize.height : cameraDimensions.height / cameraDimensions.width * windowSize.width);
		} else {
			cameraAspectRatio = cameraDimensions.height / cameraDimensions.width;
			return (screenAspectRatio > cameraAspectRatio ? windowSize.height : cameraDimensions.width / cameraDimensions.height * windowSize.width);
		}
	}

	const toggle = useCallback(() => {
		setOpen(!open);
		setShowAlignmentGrid(!showAlignmentGrid);
	}, [open, setOpen, showAlignmentGrid, setShowAlignmentGrid]);

	const cameraAllowed = stream => {
		let {width, height} = stream.getTracks()[0].getSettings();
		setCameraDimensions({width, height});
		setTimeout(() => {
			setShowAlignmentGrid(true);
		}, 500);
	};

	const capture = useCallback(() => {
		setEnableCameraButton(false);
		const src = webcamRef.current.getScreenshot();
		handleAdd(src);
		toggle();
	}, [webcamRef, toggle, handleAdd]);

	useEffect(() => {
		setEnableCameraButton(true);
		setOpen(isOpen);
	}, [isOpen]);

	useEffect(() => {
		setScreenAspectRatio(windowSize.width / windowSize.height);
		setIsLandscape(windowSize.height < windowSize.width);
	}, [windowSize.height, windowSize.width]);

	return (
		<Modal
			isOpen={open}
			toggle={toggle}
			onClosed={closeModal}
			className="camera"
		>
			<Webcam
				audio={false}
				ref={webcamRef}
				style={{
					position: 'fixed',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '100%',
					height: '100%'
				}}
				width={videoConstraints.width}
				height={videoConstraints.height}
				screenshotFormat="image/png"
				videoConstraints={videoConstraints}
				onUserMediaError={handleFallback}
				onUserMedia={cameraAllowed}
				forceScreenshotSourceSize={true}
				screenshotQuality={1}
			/>
			{
				showAlignmentGrid &&
					<div className="camera-frame" style={{width: calculateCameraViewWidth(), height: calculateCameraViewHeight()}}>
						<div style={{position: 'absolute', top: '0', left: '0'}}>
							<FrameCorner/>
						</div>
						<div style={{position: 'absolute', top: '0', right: '0', transform: 'rotate(90deg)'}}>
							<FrameCorner/>
						</div>
						<div style={{position: 'absolute', bottom: '0', right: '0', transform: 'rotate(180deg)'}}>
							<FrameCorner/>
						</div>
						<div style={{position: 'absolute', bottom: '0', left: '0', transform: 'rotate(270deg)'}}>
							<FrameCorner/>
						</div>
						<CloseCamera
							className="close-camera"
							whileTap={{
								scale: 0.7
							}}
							transition={{
								duration: 0.2
							}}
							onClick={toggle}
						>
							<Close
								stroke={css.white}
							/>
						</CloseCamera>
					</div>
			}
			{
				enableCameraButton &&
					<CameraButton
						className="camera-button"
						onClick={capture}
					>
						<CameraButtonPress
							className="camera-button-press"
							whileTap={{scale: 0.7}}
							transition={{
								duration: 0.2
							}}
						>
						&nbsp;
						</CameraButtonPress>
					</CameraButton>
			}
		</Modal>
	);
}

export default OpenCamera;