import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useWindowSize from '../../useWindowSize';

import FullScreenDocumentModal from './FullScreenDocumentModal';

import {
	CloseModal,
	Image,
	Doc,
	RotateImageButton,
	RotateImageIcon,
	DocumentContainer
} from './Styles';

import Close from '../../Svgs/Close';

import {
	modalAction,
	imageRotation
} from './imageModalAnimations';

const FullScreenDocument = ({css, file, isOpen, closeModal, isMobile}) => {

	const { t } = useTranslation('uploadComponent');

	const [open, setOpen] = useState(isOpen);
	const [angle, setAngle] = useState(0);
	const [scale, setScale] = useState(1);

	const windowSize = useWindowSize();

	const toggle = () => {
		setAngle(0);
		setScale(1);
		setOpen(!open);
		setTimeout(() => {
			closeModal();
		}, 100);
	};

	const rotateImage = () => {
		setAngle(angle + 90 % 360);
	};

	const isImage = () => (file.mimeType && !file.mimeType.includes('pdf'));

	useEffect(() => {
		if (isOpen) {
			setTimeout(() => {
				const contentSize = document.querySelector(`.modal-container`);
				const imageSize = document.querySelector('.image');
				setAngle(0);
				setScale(contentSize && contentSize.offsetWidth > contentSize.offsetHeight ? imageSize.height / imageSize.width : imageSize.width / imageSize.height);
			}, 1000);
		}
		setOpen(isOpen);
	}, [isOpen, windowSize]);

	if (!file) return null;

	return (
		<FullScreenDocumentModal
			id="viewDocModal"
			isOpen={open}
		>
			<DocumentContainer className="document-container">
				<CloseModal
					className="close-modal"
					whileHover={modalAction}
					whileTap={modalAction}
					onClick={toggle}
				>
					<Close
						stroke={css.white}
					/>
				</CloseModal>
				{
					file &&
						(
							isImage() 
							?
								<Image
									className="image"
									src={file.data}
									alt={file.name}
									data-id={file.id}
									animate={imageRotation(angle, scale)}
								/>
							: 
								<Doc
									data={file.data}
									type={file.mimeType}
								/>
						)
				}
				<RotateImageButton
					className="rotate-image-button"
					onClick={rotateImage}
				>
					<RotateImageIcon
						className="fas fa-undo rotate-image-icon"
					/>
				</RotateImageButton>
			</DocumentContainer>
		</FullScreenDocumentModal>
	);

}

export default FullScreenDocument;