const uploadContainerArea = {
	variants: {
		open: {
			minHeight: 0,
			maxHeight: '100%',
			display: 'flex'
		},
		closed: {
			minHeight: 0,
			maxHeight: '0%',
			display: 'none'
		}
	}
};

const uploadArea = {
	variants: {
		full: {
			opacity: 1,
			height: '100%',
			width: '100%',
			display: 'block',
			minWidth: 0
		},
		half: {
			opacity: 1,
			height: '100%',
			width: '50%',
			display: 'block',
			minWidth: 0
		},
		closed: {
			width: '0%',
			height: '0%',
			opacity: 0,
			display: 'none',
			minWidth: 0
		}
	}
};

const pendingFileArea = {
	variants: {
		full: {
			opacity: 1,
			height: '100%',
			width: '100%',
			display: 'block'
		},
		half: {
			opacity: 1,
			height: '100%',
			width: '50%',
			display: 'block'
		},
		closed: {
			width: '0%',
			height: '0%',
			opacity: 0,
		}
	}
};

const galleryArea = {
	variants: {
		open: {
			height: '100%',
			minHeight: 0,
			maxHeight: '90%',
			display: 'block'
		},
		closed: {
			height: '0%',
			minHeight: 0,
			display: 'none'
		}
	}
}

export {
	uploadContainerArea,
	uploadArea,
	pendingFileArea,
	galleryArea
};