import * as React from "react";

function FrameCorner() {
  return (
    <svg height="55" width="55">
      <path stroke="#000" strokeWidth='2' fill="#fff" vectorEffect='non-scaling-stroke' d="M2,2 l50,0 l0,15 l-35,0 l0,35 l-15,0 z" />
    </svg>
  )
}

export default FrameCorner;