import { motion } from 'framer-motion';
import styled from 'styled-components';

export const HiddenInputLabel = styled(motion.label)`
	width: 100%;
	height: 100%;
	min-height: 100%;
	border: 1px solid ${props => props.theme.black};
	cursor: pointer;
	outline: 2px dashed ${props => props.theme.black};
	padding: 20px;
`;

export const DropAreaLabels = styled.div`
	text-align: center;
	margin: 10px;
	font-size: 2rem;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	min-width: 0;
	pointer-events: none;
`;

export const PsuedoBrowseButton = styled.div`
	background-color: ${props => props.theme.secondary};
    border-radius: 15px;
    color: ${props => props.theme.white};
    font-size: 1.3rem;
    margin: 0 auto;
    width: 15%;
    min-width: 10rem;
`;

export const FileTypes = styled(motion.div)`
	font-size: 1rem;
	border-radius: 25px;
`;

export const FileSizes = styled(motion.div)`
	font-size: 1rem;
	border-radius: 25px;
`;