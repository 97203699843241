import React from 'react';

export default function Camera(props) {

	return (
		<svg
			width='40'
			fill="transparent"
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			viewBox="-5 10 100 100"
		>
			<g transform="translate(0,-952.36218)">
				<path
					// style="text-indent:0;text-transform:none;direction:ltr;block-progression:tb;baseline-shift:baseline;color:#000000;enable-background:accumulate;"
					style={{
						textIndent:0,
						textTransform:'none',
						direction:'ltr',
						blockProgression:'tb',
						baselineShift:'baseline',
					 	color:'#000000',
						enableBackground:'accumulate'
					}}
					d="m 82.000002,959.36218 c -1.1046,0 -2,0.8954 -2,2 l 0,7 -7,0 c -1.10457,0 -2,0.8954 -2,2 0,1.1046 0.89543,2 2,2 l 7,0 0,7 c 0,1.1046 0.8954,2 2,2 1.1046,0 2,-0.8954 2,-2 l 0,-7 7,0 c 1.10457,0 2,-0.8954 2,-2 0,-1.1046 -0.89543,-2 -2,-2 l -7,0 0,-7 c 0,-1.1046 -0.8954,-2 -2,-2 z m -48,24 -7,12 -14,0 c -3.323984,0 -6.000004,2.676 -6.000004,6.00002 l 0,38 c 0,3.324 2.67602,6 6.000004,6 l 62,0 c 3.32398,0 6,-2.676 6,-6 l 0,-38 c 0,-3.32402 -2.67602,-6.00002 -6,-6.00002 l -14,0 -7,-12 -20,0 z m 10,20.00002 c 8.81286,0 16,7.1871 16,16 0,8.8129 -7.18714,16 -16,16 -8.81287,0 -16,-7.1871 -16,-16 0,-8.8129 7.18713,-16 16,-16 z m 0,4 c -6.65111,0 -12,5.3489 -12,12 0,6.6511 5.34889,12 12,12 6.6511,0 12,-5.3489 12,-12 0,-6.6511 -5.3489,-12 -12,-12 z"
					fill={props.fill}
					fillOpacity="1"
					stroke={props.stroke}
					marker="none"
					visibility="visible"
					display="inline"
					overflow="visible"
				></path>
			</g>
		</svg>
	);
}