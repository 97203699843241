import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
	Button
} from 'reactstrap';

import ImageTile from './ImageTile/ImageTile';
import FullScreenDocument from './ImageModal/FullScreenDocument';

import {
	GalleryContainer,
	AnimatedFiles,
	DeleteFilesContainer,
	Heading,
	DeleteFilesActions,
	LoadingContainer,
	ImgLoading
} from './Styles';

import {
	staggeredImages
} from './fileGalleryAnimations';

const FileGallery = ({css, isMobile, uploaded, retrieveFile, removeSingleFile, removeManyFiles, onUploadRetrieved, doubleCheckDelete, cancelDoubleCheckDelete}) => {

	const { t } = useTranslation('uploadComponent');
	const [files, setFiles] = useState([]);
	const [multipleDelete, setMultipleDelete] = useState(false);
	const [selectedForDeletion, setSelectedForDeletion] = useState([]);
	const [viewing, setViewing] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [isRetrieving, setIsRetrieving] = useState(false);
	const [retrievedIds, setRetrievedIds] = useState([]);

	const deleteFile = id => {
		removeSingleFile(id);
	};

	const deleteManyFiles = id => {
		setMultipleDelete(true);
		setSelectedForDeletion([...selectedForDeletion, id]);
	};

	const removeFromDeleteMany = id => {
		setSelectedForDeletion(prevFiles => ([...prevFiles.filter(index => index !== id)]));
	};

	const deleteSelectedFiles = () => {
		removeManyFiles(selectedForDeletion);
		setMultipleDelete(false);
	};

	const cancelDelete = () => {
		setMultipleDelete(false);
		setSelectedForDeletion([]);
	};

	const viewFile = id => {
		setViewing(files.find(file => file.id === id));
		setIsOpen(true);
	};

	const closeModal = () => {
		setViewing(null);
		setIsOpen(false);
	};

	useEffect(() => {
		const uploadedIds = uploaded.map(file => file.id);
		const allGood = uploadedIds.every(id => retrievedIds.includes(id) === true);

		if (uploaded && uploaded.length && !isRetrieving && !allGood) {
			const retrieveFiles = async () => {
				setIsRetrieving(true);
				const pending = await uploaded.map(async file => {
					const response = await retrieveFile(file);
					const isPdf = response.type === "application/pdf";

					return { 
						...file,
						...response,
						blob: isPdf && response,
					};
				});

				const retrieved = await Promise.all(pending);
				setRetrievedIds(retrieved.map(returned => returned.id));
				setFiles(retrieved);
				setIsRetrieving(false);
			};

			retrieveFiles();
		} else {
			setFiles([]);
			setIsRetrieving(false);
		}
	}, [uploaded]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<GalleryContainer
			className="gallery-container"
			animate={{
				height: '100%'
			}}
		>
			{
				multipleDelete &&
					<DeleteFilesContainer className="delete-files-container">
						<Heading className="heading">
							<span>{t('fileGallery.deleteSelectedFiles')}</span>
						</Heading>
						<DeleteFilesActions className="delete-files-actions">
							<Button color="link" onClick={cancelDelete}>{t('buttons.cancel')}</Button>
							<Button color="danger" onClick={deleteSelectedFiles}>
								<i className="far fa-trash-alt mr-2"></i>{t('buttons.deleteSelected')}
							</Button>
						</DeleteFilesActions>
					</DeleteFilesContainer>
			}
			<AnimatedFiles
				className="animate-files"
				animate={files.length ? 'show' : 'hide'}
				variants={staggeredImages.variants}
			>
				{
					uploaded && uploaded.length && !files.length && uploaded.map((file, idx) => (
						<LoadingContainer key={idx} className="loading-container">
							<ImgLoading className="img-loading fas fa-circle-notch fa-spin"/>
						</LoadingContainer>
					))
				}
				{
					files.map((file, idx) => (
						<ImageTile
							key={file.id}
							id={idx}
							file={file}
							deleteManyFiles={deleteManyFiles}
							removeFromDeleteMany={removeFromDeleteMany}
							deletion={multipleDelete}
							viewFile={viewFile}
							deleteFile={deleteFile}
							isMobile={isMobile}
							css={css}
							enableLongPressDelete={files.length > 1}
							onImageLoad={onUploadRetrieved}
							doubleCheckDelete={doubleCheckDelete}
							cancelDoubleCheckDelete={cancelDoubleCheckDelete}
						/>
					))
				}
			</AnimatedFiles>
			<FullScreenDocument
				file={viewing}
				isOpen={isOpen}
				closeModal={closeModal}
				isMobile={isMobile}
				css={css}
			/>
		</GalleryContainer>
	);

}

FileGallery.propTypes = {
	uploaded: PropTypes.array
}

FileGallery.defaultProps = {

}

export default FileGallery;