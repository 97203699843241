import { motion } from 'framer-motion';
import styled from 'styled-components';
import { utils } from '@champtitles/utilities';

export const ImageContainer = styled(motion.div)`
	position: relative;
	padding: 1px;
	cursor: pointer;
	opacity: 0;
	width: 100%;
	height: 100%;
`;

export const DeleteIcon = styled(motion.i)`
	color: ${props => props.theme.white};
	font-size: 1.25rem;
	position: absolute;
	top: 0.3rem;
	right: 0.3rem;
	padding: 0.7rem;
	border-radius: 50%;
	background-color: rgba(${props => utils.hexToRgb(props.theme.black)}, 0.5);
`;

export const ViewDeleteSelected = styled.div.attrs(props => ({
	backgroundColor: `rgba(${props.selectedForDeletion ? utils.hexToRgb(props.theme.danger) + ',0.3' : utils.hexToRgb(props.theme.black) + ',0.3'})`
}))`
	position: absolute;
	top: 0;
	left: 0;
	background-color: ${props => props.backgroundColor};
	width: 100%;
	height: 100%;
`;

export const DeleteSelectionContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
`;

export const MessageContentContainer = styled.div`
	text-align: center;
	font-size: 0.9rem;
	background-color: rgba(${props => utils.hexToRgb(props.theme.danger)}, 0.3);
	color: ${props => props.theme.danger}
`;

export const FileLoadError = styled.div`
	text-align: center;
	color: ${props => props.theme.danger};
	font-weight: bold;
`;

export const ErrorIcon = styled.i`
	font-size: 5rem;
`;