import { motion } from 'framer-motion';
import styled from 'styled-components';
import { utils } from '@champtitles/utilities';

export const CloseModal = styled(motion.div)`
	position: absolute;
	top: 1rem;
	right: 1rem;
	cursor: pointer;
	padding: 0.7rem;
	border-radius: 50%;
	background-color: rgba(${props => utils.hexToRgb(props.theme.black)},0.5);
	z-index: 1;
`;

export const Image = styled(motion.img).attrs(props => ({
	maxWidth: `100${[90, 270].includes(props.angle) ? 'vh' : 'vw'}`,
	maxHeight: `100${[90, 270].includes(props.angle) ? 'vw' : 'vh'}`
}))`
	max-width: ${props => props.maxWidth};
	max-height: ${props => props.maxHeight};
	object-fit: contain;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	position: absolute;
`;

export const Doc = styled(motion.object)`
	max-width: 100%;
	max-height: 100%;
`;

export const RotateImageButton = styled.div`
	position: absolute;
	border-radius: 50%;
	border: 3px solid ${props => props.theme.white};
	width: 4rem;
	height: 4rem;
	background-color: rgba(${props => utils.hexToRgb(props.theme.black)},0.5);

	@media screen and (orientation:portrait) {
		margin: 0 auto;
		bottom: 25px;
		left: 0;
		right: 0;
	}

	@media screen and (orientation:landscape) {
		margin: auto 0;
		bottom: 0;
		top: 0;
		right: 25px;
	}
`;

export const RotateImageIcon = styled.i`
	position: absolute;
	color: ${props => props.theme.white};
	border-radius: 50%;
	width: 3rem;
	height: 3rem;
	font-size: 2rem;
	transform: scale(-1, 1);
	left: 0.3rem;
	top: 0.9rem;
	cursor: pointer;
`;





export const ModalContainer = styled(motion.div)`
	z-index: 9999;
	position: absolute;
	top: ${props => props.position.top}px;
	width: 100%;
	height: 100%;
	text-align: center;
	background-color: ${props => props.theme.black};
	overflow: hidden;
`;

export const DocumentContainer = styled.div`
`;