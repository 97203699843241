import React, { useState, useEffect } from 'react';

import DropArea from './DropArea';

const DragNDrop = ({id, css, files, uploaded, maxFiles, maxFileSize, autoUpload, allowedFileTypes, isUploading, addMultipleFiles, uploadFiles}) => {

	const [filesRemoved, setFilesRemoved] = useState(false);
	const [fileSizesRemoved, setFileSizesRemoved] = useState(false);

	const handleAdd = uploads => {
		const fileSizesAllowed = fileSizeAllowed(uploads);

		setFileSizesRemoved(uploads.length !== fileSizesAllowed.length);

		const filesAllowed = fileTypesAllowed(fileSizesAllowed);

		setFilesRemoved(uploads.length !== filesAllowed.length && fileSizesAllowed.length !== filesAllowed.length);

		addMultipleFiles(filesAllowed);
	};

	const resetFilesRemoved = () => {
		setFilesRemoved(false);
		setFileSizesRemoved(false);
	};

	const fileTypesAllowed = uploads => {

		const extChecked = [...uploads].filter(attrs => {
			const {file} = attrs;
			const name = file.name.toLowerCase().split('.');
			return allowedFileTypes.includes(name[name.length - 1]);
		});

		return checkMaxFiles(extChecked);
	};

	const fileSizeAllowed = uploads => {
		const sizeChecked = [...uploads].filter(attrs => {
			const {file} = attrs;
			return file.size <= maxFileSize;
		});

		return checkMaxFiles(sizeChecked);
	};

	const checkMaxFiles = filteredFiles => {
		let additionalFilesAllowed = filteredFiles;

		if (maxFiles && (files.length + filteredFiles.length + uploaded.length) >= maxFiles) {
			const totatlSpotsAvailable = maxFiles - uploaded.length - files.length;
			additionalFilesAllowed = filteredFiles.slice(0, totatlSpotsAvailable);
		}

		return additionalFilesAllowed;
	};

	useEffect(() => {
		if (autoUpload && files.length && !files[0].isUploading && !files[0].complete) uploadFiles(files);
	}, [files, autoUpload, uploadFiles]);

	return (
		<DropArea
			id={id}
			maxFiles={maxFiles}
			maxFileSize={maxFileSize}
			addFiles={handleAdd}
			allowedFileTypes={allowedFileTypes}
			isUploading={isUploading}
			filesRemoved={filesRemoved}
			fileSizesRemoved={fileSizesRemoved}
			resetFilesRemoved={resetFilesRemoved}
			css={css}
		/>
	);
}

export default DragNDrop;