import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Bar = styled(motion.span)`
	color: ${props => props.theme.gray400};
	width: 0%;
	background-color: ${props => props.theme.secondary};
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
`;

export const LabelContainer = styled.div.attrs(props => ({
	color: props.color || props.theme.gray800
}))`
	color: ${props => props.color};
	z-index: 10;
	min-width: 0;
`;

export const ActiveProgressBar = styled.div.attrs(props => ({
	color: props.color || props.theme.white
}))`
	color: ${props => props.color};
	padding: 5px;
	display: flex;
	justify-content: right;
	align-items: center;
	z-index: 10;
`;