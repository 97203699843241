import { motion } from 'framer-motion';
import styled from 'styled-components';
import { utils } from '@champtitles/utilities';

export const OpenCameraContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const CameraIcon = styled(motion.div)`
	padding: 8px;
	background-color: rgba(${props => utils.hexToRgb(props.theme.secondary)},0.95); 
	border: 1px solid ${props => props.theme.secondary};
	color: white;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	text-align: center;
	margin: 5px;
	font-size: 30px;
	z-index: 100;
	box-shadow: 0px 3px 3px 2px ${props => props.theme.gray400};
`;

export const FileTypes = styled(motion.div)`
	font-size: 1rem;
	border-radius: 25px;
`;

export const FileSizes = styled(motion.div)`
	font-size: 1rem;
	border-radius: 25px;
`;