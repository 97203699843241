const deletion = {
	variants: css => ({
		default: {
			color: css.gray800,
			display: 'flex',
			width: '100%',
			opacity: 1,
			backgroundColor: css.gray400
		},
		focus: {
			color: css.white,
			backgroundColor: css.danger,
			borderColor: css.white
		},
		removing: {
			width: '0%',
			opacity: 0,
			transitionEnd: {
				display: 'none'
			}
		}
	})
}

export {
	deletion
};