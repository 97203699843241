import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import {
	ModalContainer
} from './Styles';

const modalRoot = document.querySelector('body');
const el = document.createElement('div');

const FullScreenDocumentModal = ({isOpen, id, children}) => {

	const [open, setOpen] = useState(isOpen);

	useEffect(() => {
		if (isOpen) {
			el.id = id;
			modalRoot.appendChild(el);
			modalRoot.style.overflow = 'hidden';
			setOpen(true);
		} else {
			const elExists = document.getElementById(id);
			if (elExists) {
				modalRoot.removeChild(el);
				modalRoot.style.overflow = 'auto';
			}
			setOpen(false);
		}
	}, [isOpen]);

	return ReactDOM.createPortal(
			<ModalContainer
				className="modal-container"
				animate={open ? 'open' : 'closed'}
				variants={{
					open: {
						opacity: 1,
						scale: 1
					},
					closed: {
						opacity: 0,
						scale: 0
					},
					transition: {
						ease: 'easeIn',
						duration: 0.5
					}
				}}
				position={{
					top: window.scrollY ? window.scrollY : window.pageYOffset
				}}
			>
				{children}
			</ModalContainer>
		, el);
};

export default FullScreenDocumentModal;