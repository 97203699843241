import { utils } from '@champtitles/utilities';

const fileTypeWarning = css => ({
	backgroundColor: `rgba(${utils.hexToRgb(css.danger)}, 0.7)`,
	color: css.white,
	transition: {
		repeat: 1,
		repeatType: 'reverse',
		duration: 1
	}
});

export {
	fileTypeWarning
};