import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Bar,
	LabelContainer,
	ActiveProgressBar
} from './Styles';

import {
	progressBar
} from './progressBarAnimations';

const ProgressBar = ({css, file, allUploaded, removing, children}) => {

	const { t } = useTranslation('uploadComponent');
	const [progress, setProgress] = useState(0);
	const [step, setStep] = useState(0.03);
	const [timer, setTimer] = useState(null);

	useEffect(() => {
		const simulateProgress = () => {
			let currentProgress = 0;

			setTimer(setInterval(() => {

				currentProgress += step;
				setProgress(Math.round(Math.atan(currentProgress) / (Math.PI / 2) * 100 * 1000) / 1000);
				
				if (progress >= 100) {
					clearInterval(timer);
				} else if (progress >= 70) {
					setStep(0.01);
				}

			}, 100));
		};

		if (file.isUploading && !timer) simulateProgress();
	}, [file.isUploading, progress, step, timer]);

	useEffect(() => {
		if (file.error) {
			setProgress(100);
			clearInterval(timer);
		} else if (file.complete) {
			setProgress(100);
			clearInterval(timer);
		}
	}, [file.complete, file.error, timer]);

	return (
		<Fragment>
			{
				(file.isUploading || file.complete) &&
					<Bar
						className="bar"
						animate={progressBar.variants(progress, file.error, css)}
						onAnimationComplete={allUploaded}
					/>
			}
			<LabelContainer
				className="label-container"
				color={(progress || removing) && css.white}
			>
				{children}
			</LabelContainer>
			{
				(file.isUploading || file.complete) &&
					<ActiveProgressBar className="active-progress-bar">
						{
							file.error
							?
								<Fragment>
									<span className="text-right mr-2">
										{t('pendingUploads.error')}
									</span>
									<i className="fas fa-exclamation-circle text-right"></i>
								</Fragment>
							:
								<Fragment>
									<span className="text-right mr-2">
										{t('pendingUploads.uploading')} ({(progress).toFixed(0)}%)
									</span>
									<i className={`fa ${file.isUploading ? 'fa-circle-o-notch fa-spin' : 'fa-check-circle-o'} text-right`}/>
								</Fragment>
						}
					</ActiveProgressBar>
			}
		</Fragment>
	);

}

export default ProgressBar;