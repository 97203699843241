import { motion } from 'framer-motion';
import styled from 'styled-components';

export const CameraButton = styled(motion.div)`
	position: fixed;
	border-radius: 50%;
	border: 3px solid ${props => props.theme.white};
	width: 4rem;
	height: 4rem;


	@media screen and (orientation:portrait) {
		margin: 0 auto;
		bottom: 25px;
		left: 0;
		right: 0;
	}

	@media screen and (orientation:landscape) {
		margin: auto 0;
		bottom: 0;
		top: 0;
		right: 25px;
	}
`;

export const CameraButtonPress = styled(motion.div)`
	position: absolute;
	background-color: ${props => props.theme.white};
	border-radius: 50%;
	width: 3.15rem;
	height: 3.1rem;

	@media screen and (orientation:portrait) {
	    left: 0.25rem;
	    top: 0.25rem;
	}

	@media screen and (orientation:landscape) {
	    left: 0.25rem;
	    top: 0.3rem;
	}
`;

export const CloseCamera = styled(motion.div)`
	position: fixed;
	top: 1.5rem;
	right: 1.5rem;
`;