import {useState, useEffect} from 'react';

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined
	});

	useEffect(() => {
		const handleOrientation = () => {
			setTimeout(() => {
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight
				});
			}, 1000);
		};

		window.addEventListener('resize', handleOrientation);

		handleOrientation();

		return () => {
			window.removeEventListener('resize', handleOrientation);
		}
	}, []);

	return windowSize;
}

export default useWindowSize;