import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button
} from 'reactstrap';

import {
	PendingFilesContainer,
	ManualUpload,
	Heading,
	ButtonContainer
} from './Styles';

import PendingFile from '../PendingFileContainer/PendingFile';

const PendingFiles = ({css, files, autoUpload, isUploading, removeFile, uploadAll, allFilesUploaded, resetToEmpty}) => {

	const { t } = useTranslation('uploadComponent');

	const [complete, setComplete] = useState(false);

	const allUploaded = () => {
		const allComplete = files.length && files.every(file => file.complete === true);

		if (allComplete) {
			const errored = files.filter(file => file.error === true)
			allFilesUploaded(errored);
			setComplete(true);
		}
	};

	const reset = () => {
		setComplete(false);
		resetToEmpty();
	};

	return (
		<PendingFilesContainer
			className="pending-files-container"
		>
			{
				!autoUpload &&
					<ManualUpload className="manual-upload">
						<Heading className="heading">
							{t('pendingUploads.pendingUploads')}
						</Heading>
						<ButtonContainer className="button-container">
							<Button color="primary" size="sm" onClick={uploadAll}>
								<i className="fas fa-upload mr-2"></i>{t('buttons.uploadFiles')}
							</Button>
						</ButtonContainer>
					</ManualUpload>
			}
			{
				!isUploading && complete &&
					<Button outline color="secondary" size="sm" onClick={reset} className="m-2">{t('buttons.clear')}</Button>
			}
			{
				files.map((file, idx) => <PendingFile
					key={idx}
					id={idx}
					file={file}
					removeFile={removeFile}
					allUploaded={allUploaded}
					css={css}
				/>)
			}
		</PendingFilesContainer>
	);




}

export default PendingFiles;