import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Input
} from 'reactstrap';

import { utils } from '@champtitles/utilities';

import {humanReadableSize} from '../ParseFileSize';

import OpenCamera from './OpenCamera/OpenCamera';

import {
	OpenCameraContainer,
	CameraIcon,
	FileTypes,
	FileSizes
} from './Styles';

import {
	fileTypeWarning
} from './mobileAnimations';

import Camera from '../Svgs/Camera';

const Mobile = ({id, css, files, uploaded, maxFiles, autoUpload, allowedFileTypes, maxFileSize, cameraQuality, isUploading, addFile, addMultipleFiles, uploadFiles}) => {

	const { t } = useTranslation('uploadComponent');

	const [isOpen, setIsOpen] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);
	const [filesRemoved, setFilesRemoved] = useState(false);
	const [fileSizesRemoved, setFileSizesRemoved] = useState(false);

	const openCamera = () => {
		if (isBlocked) handleFallback();
		else setIsOpen(true);
	};

	const closeCamera = () => {
		setIsOpen(false);
	};

	const handleAdd = imgURI => {
		let file = utils.dataURItoBlob(imgURI, id);

		const fileSizesAllowed = fileSizeAllowed([{file}]);

		if (fileSizesAllowed.length > 0) {
			addMultipleFiles([{
				file,
				isUploading: false,
				complete: false,
				error: false
			}]);
		} else {
			setFileSizesRemoved(true);
		}
	};

	const handleFallback = () => {
		if (!isBlocked) {
			setIsBlocked(true);
			setIsOpen(false);
		}

		document.getElementById(`file${id}`).click();
	};

	const handleFilesSelected = e => {
		const uploads = [...e.target.files].map(file => ({
				file,
				isUploading: false,
				complete: false,
				error: false
			}));

		const fileSizesAllowed = fileSizeAllowed(uploads);

		setFileSizesRemoved(uploads.length !== fileSizesAllowed.length);

		const filesAllowed = fileTypesAllowed(fileSizesAllowed);

		setFilesRemoved(uploads.length !== filesAllowed.length && fileSizesAllowed.length !== filesAllowed.length);

		addMultipleFiles(filesAllowed);
	};

	const fileTypesAllowed = uploads => {

		const extChecked = [...uploads].filter(attrs => {
			const {file} = attrs;
			const name = file.name.toLowerCase().split('.');
			return allowedFileTypes.includes(name[name.length - 1]);
		});

		return checkMaxFiles(extChecked);
	};

	const fileSizeAllowed = uploads => {
		const sizeChecked = [...uploads].filter(attrs => {
			const {file} = attrs;
			return file.size <= maxFileSize;
		});

		return checkMaxFiles(sizeChecked);
	};

	const checkMaxFiles = filteredFiles => {
		let additionalFilesAllowed = filteredFiles;

		if (maxFiles && (files.length + filteredFiles.length + uploaded.length) >= maxFiles) {
			const totatlSpotsAvailable = maxFiles - uploaded.length - files.length;
			additionalFilesAllowed = filteredFiles.slice(0, totatlSpotsAvailable);
		}

		return additionalFilesAllowed;
	};

	useEffect(() => {
		if (autoUpload && files.length && !files[0].isUploading && !files[0].complete) uploadFiles(files);
	}, [files, autoUpload, uploadFiles]);

	return (
		<Fragment>
			<Input
				id={`file${id}`}
				type="file"
				name="file[]"
				style={{
					width: '0.1px',
					height: '0.1px',
					visibility: 'hidden'
				}}
				multiple
				onChange={handleFilesSelected}
				disabled={isUploading}
			/>
			<OpenCameraContainer className="open-camera-container">
				<CameraIcon
					className="camera-icon"
					whileTap={{scale: 0.98, boxShadow: `0px 0px 0px 0px ${css.white}`}}
					transition={{
						duration: 0.1
					}}
					onClick={isUploading ? closeCamera : openCamera}
				>
					<Camera
						fill={css.white}
						stroke={css.white}
					/>
				</CameraIcon>
			</OpenCameraContainer>

			<OpenCamera
				isOpen={isOpen}
				closeModal={closeCamera}
				handleAdd={handleAdd}
				handleFallback={handleFallback}
				cameraQuality={cameraQuality}
				css={css}
			/>
			{
				isBlocked &&
					<Fragment>
						<FileTypes
							className="file-types"
							animate={filesRemoved && fileTypeWarning(css)}
						>
							{t('desktopUpload.allowedFileTypes')}: {allowedFileTypes.join(', ').toUpperCase()}
						</FileTypes>
						<FileSizes
							className="file-sizes"
							variants={fileTypeWarning(css)}
							animate={fileSizesRemoved ? 'error' : 'noError'}
						>
							{t('desktopUpload.maxFileSize')}: {humanReadableSize(maxFileSize)}
						</FileSizes>
					</Fragment>
			}
		</Fragment>
	);
}

export default Mobile;