import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Input
} from 'reactstrap';

import {humanReadableSize} from '../ParseFileSize';

import {
	HiddenInputLabel,
	DropAreaLabels,
	PsuedoBrowseButton,
	FileTypes,
	FileSizes
} from './Styles';

import {
	hiddenInputLabel,
	fileTypeWarning,
	fileSizeWarning
} from './desktopAreaAnimations';

const DropArea = ({id, css, files = [], maxFiles, maxFileSize, allowedFileTypes, isUploading, filesRemoved, fileSizesRemoved, addFiles, resetFilesRemoved, children}) => {

	const { t, i18n } = useTranslation('uploadComponent');

	const [dragging, setDragging] = useState(false);
	const [draggingEnabled, setDraggingEnabled] = useState(files.length <= maxFiles);

	const handleDragStart = e => {
		e.preventDefault();
		e.stopPropagation();

		e.dataTransfer.clearData();
	};

	const handleDrag = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDragIn = e => {
		e.preventDefault();
		e.stopPropagation();

		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			setDragging(true);
		}
	};

	const handleDragOut = e => {
		e.preventDefault();
		e.stopPropagation();
		setDragging(false);
	};

	const handleDrop = e => {
		e.preventDefault();
		e.stopPropagation();
		setDragging(false);

		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			addFiles([...e.dataTransfer.files].map(file => ({
					file,
					isUploading: false,
					complete: false,
					error: false
				})
			));
		}
	};

	const handleFilesSelected = e => {
		addFiles([...e.target.files].map(file => ({
				file,
				isUploading: false,
				complete: false,
				error: false
			})
		));
	};

	useEffect(() => {
		setDraggingEnabled(!isUploading);
	}, [isUploading, setDraggingEnabled]);

	const dragProps = draggingEnabled ? {
		onDragStart: handleDragStart,
		onDragEnter: handleDragIn,
		onDragLeave: handleDragOut,
		onDragOver: handleDrag,
		onDrop: handleDrop
	} : {};

	return (
		<Fragment>
			<Input
				id={`file${id}`}
				type="file"
				name="file[]"
				style={{
					display: 'none'
				}}
				multiple
				onClick={e => e.target.value = null}
				onChange={handleFilesSelected}
				disabled={isUploading}
			/>
			<HiddenInputLabel
				{...dragProps}
				htmlFor={`file${id}`}
				className="drop-area"
				initial="notDragging"
				animate={dragging ? 'dragging' : 'notDragging'}
				variants={hiddenInputLabel.variants(css)}
			>
				<DropAreaLabels className="drop-area-labels">
					<div>
						{t('desktopUpload.dropArea')}
					</div>
					<PsuedoBrowseButton className="psuedo-browse-button">
						{t('desktopUpload.browse')}
					</PsuedoBrowseButton>
					<FileTypes
						className="file-types"
						variants={fileTypeWarning.variants(css)}
						animate={filesRemoved ? 'error' : 'noError'}
						onAnimationComplete={resetFilesRemoved}
					>
						{t('desktopUpload.allowedFileTypes')}: {allowedFileTypes.join(', ').toUpperCase()}
					</FileTypes>
					<FileSizes
						className="file-sizes"
						variants={fileSizeWarning.variants(css)}
						animate={fileSizesRemoved ? 'error' : 'noError'}
						onAnimationComplete={resetFilesRemoved}
					>
						{t('desktopUpload.maxFileSize')}: {humanReadableSize(maxFileSize)}
					</FileSizes>
				</DropAreaLabels>
			</HiddenInputLabel>
		</Fragment>
	);

}

export default DropArea;