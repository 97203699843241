import React, { useState } from 'react';

import ProgressBar from './ProgressBar/ProgressBar';

import {
	FileContainer,
	FileAttributes,
	FileName,
	FileSize,
	RemoveFile,
	RemoveIcon
} from './Styles';

import {
	deletion
} from './pendingFileAnimations';

const PendingFile = ({id, css, file, removeFile, allUploaded}) => {

	const [removing, setRemoving] = useState(false);
	
	const [deleteFocus, setDeleteFocus] = useState(false);

	const getFileSize = () => {
		const {size} = file.file;
		const i = Math.floor(Math.log(size) / Math.log(1024));
    	return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
	};

	const remove = () => {
		setRemoving(true);
	};

	const handleDeleteFocus = () => {
		setDeleteFocus(!deleteFocus);
	};

	const animationComplete = () => {
		if (removing) {
			setRemoving(false);
			setDeleteFocus(false);
			setTimeout(() => {
				removeFile(id);
			}, 0);
		}
	};

	return (
		<FileContainer
			className="file-container"
			animate={deleteFocus ? 'focus' : (removing ? 'removing' : 'default')}
			variants={deletion.variants(css)}
			onAnimationComplete={animationComplete}
		>
			<ProgressBar
				file={file}
				allUploaded={allUploaded}
				removing={deleteFocus}
				css={css}
			>
				<FileAttributes className="file-attributes">
					<FileName className="file-name">{file.file.name}</FileName>
					<FileSize className="file-size">
						{getFileSize()}
					</FileSize>
				</FileAttributes>
			</ProgressBar>
			{
				!file.isUploading && !file.complete &&
					<RemoveFile
						className="remove-file"
						onClick={remove}
					>
						<RemoveIcon
							className="remove-icon fas fa-times"
							onHoverStart={handleDeleteFocus}
							onHoverEnd={handleDeleteFocus}
						/>
					</RemoveFile>
			}
		</FileContainer>
	);
}

export default PendingFile;