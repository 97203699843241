import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	CustomInput
} from 'reactstrap';

import DeleteWarningModal from './DeleteWarningModal';

import {
	ImageContainer,
	DeleteIcon,
	ViewDeleteSelected,
	DeleteSelectionContainer,
	FileLoadError,
	ErrorIcon
} from './Styles';

import {
	container,
	deleteIconActions
} from './imageTileAnimations';

const ImageTile = ({css, file, id, viewFile, deleteFile, deleteManyFiles, removeFromDeleteMany, deletion, isMobile, enableLongPressDelete, onImageLoad, doubleCheckDelete, cancelDoubleCheckDelete}) => {

	const { t } = useTranslation('uploadComponent');
	const [selectedForDeletion, setSelectedForDeletion] = useState(false);
	const [deleteImage, setDeleteImage] = useState(false);

	let buttonPressTimer = null;

	const buttonLongPress = e => {
		const {currentTarget: { dataset: {id}}} = e;
		if (enableLongPressDelete) {
			buttonPressTimer = setTimeout(() => {
				if (!selectedForDeletion) setSelectedForDeletion(true);
				deleteManyFiles(id);
			}, 800);
		}
	};

	const buttonRelease = e => {
		buttonPressTimer = clearTimeout(buttonPressTimer);
	};

	const onCheck = e => {
		const {target: {id, checked}} = e;
		if (checked) {
			setSelectedForDeletion(true);
			deleteManyFiles(id);
		} else {
			setSelectedForDeletion(false);
			removeFromDeleteMany(id);
		}
	};

	const doubleCheck = () => {
		setDeleteImage(!deleteImage);
	};

	const deleteConfirmed = () => {
		deleteFile(file.id);
		if (cancelDoubleCheckDelete) cancelDoubleCheckDelete();
	};


	const closeModal = () => {
		setDeleteImage(false);
		if (cancelDoubleCheckDelete) cancelDoubleCheckDelete();
	};

	const isImage = () => (file.mimeType && !file.mimeType.includes('pdf'));

	const actions = {
		onMouseDown: buttonLongPress,
		onMouseUp: buttonRelease,
		onTouchStart: buttonLongPress,
		onTouchEnd: buttonRelease
	};

	const useObjectUrl = (blob) => {
		const url = useMemo(() => URL.createObjectURL(blob), [blob]);

		// cleanup the object from memory after unmount
		useEffect(() => () => URL.revokeObjectURL(url), [blob]);

		return url;
	}

	useEffect(() => {
		if (doubleCheckDelete) {
			doubleCheck();
		}
	}, [doubleCheckDelete]);

	return (
		<ImageContainer
			className="image-container"
			{...actions}
			data-id={file.id}
			data-id-position={id}
			variants={container.variants}
		>
			{
				file.error &&
					<FileLoadError>
						<ErrorIcon className="fas fa-exclamation-circle"></ErrorIcon>
						<div>{t('fileLoadError')}</div>
					</FileLoadError>
			}
			{
				!file.error &&
					(
						isImage()
						?
							<img
								src={file.data}
								alt={file.name}
								style={{width: '100%', height: 'auto', maxHeight: '100%', maxWidth: '100%', position: 'relative'}}
								data-id={file.id}
								onClick={() => viewFile(file.id)}
								onLoad={() => {if (onImageLoad) onImageLoad(file)}}
							/>
						:
							<object
								alt=""
								style={{width: '100%', height: '100%'}}
								data={useObjectUrl(file.blob)}
								type={file.mimeType}
							>
								<p>{t('documentViewError')}</p>
							</object>
					)
			}
			{
				deletion 
				?
					<Fragment>
						<ViewDeleteSelected
							className="view-delete-selected"
							selectedForDeletion={selectedForDeletion}
							onClick={() => viewFile(file.id)}
						/>
						<DeleteSelectionContainer className="delete-selection-container">
							<CustomInput 
								type="checkbox"
								checked={selectedForDeletion}
								className="checkbox-lg"
								id={file.id}
								data-id-position={id}
								onChange={onCheck}
								required
							/>
						</DeleteSelectionContainer>
					</Fragment>
				:
					(
						!file.error && 
							<DeleteIcon
								className="delete-icon far fa-trash-alt"
								onClick={doubleCheck}
								whileHover={deleteIconActions(css)}
								whileTap={deleteIconActions(css)}
							/>
					)
			}
			<DeleteWarningModal
				isOpen={deleteImage}
				closeModal={closeModal}
				deleteConfirmed={deleteConfirmed}
				css={css}
			/>
		</ImageContainer>
	)

}

export default ImageTile;