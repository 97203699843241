const modalAction = {
	scale: 0.7,
	transition: {
		duration: 0.2
	}
};

const imageRotation = (angle, scale) => ({
	rotate: angle,
	scale: [90, 270].includes(angle % 360) ? scale : 1,
	translateX: '-50%',
	translateY: '-50%',
	transition: {
		duration: 0.1
	}
});

export {
	modalAction,
	imageRotation
};