import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { motion } from 'framer-motion';
import { ThemeProvider } from 'styled-components';

import DragNDrop from './DesktopUpload/DragNDrop';
import Mobile from './MobileUpload/Mobile';
import FileGallery from './FileGallery/FileGallery';
import PendingFiles from './PendingFiles/PendingContainer/PendingFiles';
import useFilesState from './useFilesState';
import {rawFileSize} from './ParseFileSize';

import {
	uploadContainerArea,
	uploadArea,
	pendingFileArea,
	galleryArea
} from './uploadContainerAnimations';

const UploadContainer = ({id, css, i18n, isMobile, maxFiles, maxFileSize, cameraQuality, upload, retrieve, remove, onUploadRetrieved, autoUpload, allowedFileTypes, attachments, doubleCheckDelete, cancelDoubleCheckDelete}) => {

	const showMobile = isMobile !== null ? isMobile : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	const [uploaded, setUploaded] = useState(attachments);
	const [isUploading, setIsUploading] = useState(false);
	const [completed, setCompleted] = useState([]);
	const [files, addFile, removeFile, emptyFiles, updateFile, addMultipleFiles, /*removeMultipleFiles -- not using*/, setFiles] = useFilesState([]);

	maxFileSize = rawFileSize(maxFileSize);

	const uploadFiles = async () => {

		setIsUploading(true);
		let pending = [];

		for (let i = 0, iLen = files.length; i < iLen; i++) {
			let file = files[i];

			updateFile({
				...file,
				isUploading: true,
				complete: false
			}, i);

			try {
				const uploadingFile = await upload(file.file, id);
				updateFile({
					...file,
					isUploading: false,
					complete: true
				}, i);
				pending.push(Promise.resolve(uploadingFile));
			} catch (e) {
				updateFile({
					...file,
					isUploading: false,
					complete: true,
					error: true
				}, i);
			}
		}

		setCompleted(pending);
	};

	const allFilesUploaded = async errored => {
		setIsUploading(false);
		if (!errored.length) {
			emptyFiles();
		} else {
			setFiles(errored);
		}

		const successfulUploads = await Promise.all(completed);

		setUploaded(prevUploaded => ([...prevUploaded, ...successfulUploads]));
		setCompleted([]);
	};

	const retrieveFile = async file => {
		try {
			return await retrieve(file);
		} catch (e) {
			return {
				error: e
			}
		}
	};

	const removeSingleFile = async id => {
		await remove(id);
		setUploaded(prevUploaded => ([...prevUploaded.filter(file => file.id !== id)]));
	};

	const removeManyFiles = async fileIds => {

		for (let i = 0, iLen = fileIds.length; i < iLen; i++ ) {
			await remove(fileIds[i])
		}

		setUploaded([...uploaded.filter(file => !fileIds.includes(file.id))]);
	};

	const resetToEmpty = () => {
		emptyFiles();
	};

	return (
		<I18nextProvider i18n={i18n}>
			<ThemeProvider theme={css}>
				<motion.div
					initial="open"
					animate={uploaded.length >= maxFiles ? 'closed' : 'open'}
					variants={uploadContainerArea.variants}
				>
					<motion.div
						initial="full"
						animate={uploaded.length + files.length < maxFiles ? (files.length ? 'half' : 'full') : 'closed'}
						variants={uploadArea.variants}
						transition={{
							duration: 0.3
						}}
					>
						{
							showMobile
							?
								<Mobile
									id={id}
									files={files}
									maxFiles={maxFiles}
									uploaded={uploaded}
									autoUpload={autoUpload}
									allowedFileTypes={allowedFileTypes}
									maxFileSize={maxFileSize}
									uploadFiles={uploadFiles}
									addFile={addFile}
									addMultipleFiles={addMultipleFiles}
									isUploading={isUploading}
									cameraQuality={cameraQuality}
									css={css}
								/>
							:
								<DragNDrop
									id={id}
									files={files}
									maxFiles={maxFiles}
									uploaded={uploaded}
									autoUpload={autoUpload}
									allowedFileTypes={allowedFileTypes}
									maxFileSize={maxFileSize}
									uploadFiles={uploadFiles}
									addMultipleFiles={addMultipleFiles}
									isUploading={isUploading}
									css={css}
									i18n={i18n}
								/>
						}
					</motion.div>
					<motion.div
						initial="closed"
						animate={uploaded.length + files.length >= maxFiles ? 'full' : (files.length ? 'half' : 'closed')}
						variants={pendingFileArea.variants}
						transition={{
							duration: 0.3
						}}
					>
						<PendingFiles
							files={files}
							autoUpload={autoUpload}
							removeFile={removeFile}
							uploadAll={uploadFiles}
							isUploading={isUploading}
							allFilesUploaded={allFilesUploaded}
							resetToEmpty={resetToEmpty}
							css={css}
						/>
					</motion.div>
				</motion.div>
				<motion.div
					initial="closed"
					animate={uploaded.length ? 'open' : 'closed'}
					variants={galleryArea.variants}
				>
					<FileGallery
						uploaded={uploaded}
						retrieveFile={retrieveFile}
						removeSingleFile={removeSingleFile}
						removeManyFiles={removeManyFiles}
						isMobile={showMobile}
						onUploadRetrieved={onUploadRetrieved}
						css={css}
						doubleCheckDelete={doubleCheckDelete}
						cancelDoubleCheckDelete={cancelDoubleCheckDelete}
					/>
				</motion.div>
			</ThemeProvider>
		</I18nextProvider>
	);
}

UploadContainer.propTypes = {
	id: PropTypes.string.isRequired,
	i18n: PropTypes.object.isRequired,
	upload: PropTypes.func.isRequired,
	retrieve: PropTypes.func.isRequired,
	remove: PropTypes.func.isRequired,
	isMobile: PropTypes.bool,
	maxFiles: PropTypes.number,
	autoUpload: PropTypes.bool,
	attachments: PropTypes.array,
	allowedFileTypes: PropTypes.array,
	maxFileSize: PropTypes.oneOfType([
		PropTypes.string, // [b, kb, mb, gb] case-insensitive and spaces allowed
		PropTypes.number
	]),
	cameraQuality: PropTypes.oneOf(['FHD', '2K', '4K']),
	css: PropTypes.object
}

UploadContainer.defaultProps = {
	isMobile: null,
	maxFiles: 0,
	autoUpload: false,
	attachments: [],
	allowedFileTypes: ['jpg', 'jpeg', 'png', 'gif', 'pdf'],
	maxFileSize: '10 MB',
	cameraQuality: '2K',
	css: {
		white: '#fff',
		black: '#000',
		primary: '#0f0',
		secondary: '#00f',
		gray: '#333',
		gray400: '#666',
		gray800: '#aaa',
		danger: '#f00'
	}
}

export default UploadContainer;