export default {
	"buttons": {
		"cancel": "Cancel",
		"clear":"Clear",
		"delete":"Delete",
		"deleteSelected": "Delete Selected",
		"uploadFiles": "Upload Files"
	},
	"desktopUpload": {
		"dropArea": "Drop Area",
		"browse": "Browse",
		"allowedFileTypes": "Allowed File Types",
		"maxFileSize": "Max File Size"
	},
	"mobileUpload": {

	},
	"fileGallery": {
		"deleteSelectedFiles": "Delete Selected Files"
	},
	"pendingUploads": {
		"uploading": "Uploading",
		"pendingUploads": "Pending Uploads",
		"error":"Error"
	},
	"deleteWarningModal": {
		"warningMessage": "Are you sure you want to delete this image?"
	},
	"documentViewError": "Document could not be loaded.",
	"fileLoadError": "Could not load file"
};
