import { motion } from 'framer-motion';
import styled from 'styled-components';
import {utils} from '@champtitles/utilities';

export const GalleryContainer = styled(motion.div).attrs(props => ({
	backgroundColor: props.backgroundColor || props.theme.white
}))`
	background-color: ${props => props.backgroundColor};
	height: 0%;
`;

export const AnimatedFiles = styled(motion.div)`
	display: block;
	height: 100%;
`;

export const DeleteFilesContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1rem;
	background-color: rgba(${props => utils.hexToRgb(props.theme.danger)}, 0.3);
`;

export const Heading = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	width: 100%;
`;

export const DeleteFilesActions = styled.div`
	display: flex;
	white-space: nowrap;
`;

export const LoadingContainer = styled.div`
	color: ${props => props.theme.secondary};
	background-color: rgba(${props => utils.hexToRgb(props.theme.black)},0.3);
	text-align: center;
`;

export const ImgLoading = styled.i`
	font-size: 4rem;
	padding: 4rem;

`;