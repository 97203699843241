import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Modal,
	ModalBody
} from 'reactstrap';

import {
	MessageContentContainer
} from './Styles';

const DeleteWarningModal = ({css, isOpen, closeModal, deleteConfirmed}) => {

	const { t } = useTranslation('uploadComponent');
	const [open, setOpen] = useState(isOpen);

	const toggle = () => {
		setOpen(!open);
	};

	const yupDelete = () => {
		toggle();
		deleteConfirmed();
	};

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	return (
		<Modal
			className="delete-warning"
			isOpen={open}
			toggle={toggle}
			onClosed={closeModal}
			centered={true}
		>
			<MessageContentContainer className="message-content-container">
				<ModalBody>
					{t('deleteWarningModal.warningMessage')}
				</ModalBody>
				<div
					className="text-right p-2"
				>
					<Button color="link" onClick={toggle}>{t('buttons.cancel')}</Button>
					<Button color="danger" onClick={yupDelete}>{t('buttons.delete')}</Button>
				</div>
			</MessageContentContainer>
		</Modal>
	);

}

export default DeleteWarningModal;