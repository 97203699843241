const progressBar = {
	variants: (progress, errored, css) => ({
		width: `${progress}%`,
		color: css.white,
		backgroundColor: errored ? css.danger : (progress === 100 ? css.primary : css.secondary),
		transition: 0.5
	})
};

export {
	progressBar
};