const staggeredImages = {
	variants: {
		show: {
			transition: {
				staggerChildren: 0.1,
				delayChildren: 0.2
			}
		},
		hide: {
			transition: {
				staggerChildren: 0.1,
				staggerDirection: -1
			}
		}
	}
};

export {
	staggeredImages
};