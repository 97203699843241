import { useState } from 'react';

const useFilesState = (defaultFiles = []) => {
	const [files, setFiles] = useState(defaultFiles);

	return [
		files,
		(obj) => {
			//Add file to list
			setFiles(prevFiles => ([...prevFiles, obj]));
		},
		(idx) => {
			//Remove file from list
			setFiles(prevFiles => ([...prevFiles.filter((_, index) => index !== idx)]));
		},
		() => {
			//Empty list
			setFiles([]);
		},
		(obj, idx) => {
			//Update file in list
			setFiles(prevFiles => ([...prevFiles.map((file, index) => idx === index ? obj : file)]));
		},
		(list) => {
			//Add multiple files to list
			setFiles(prevFiles => ([...prevFiles, ...list]));
		},
		(idxs) => {
			//Remove multiple files from list
			setFiles(prevFiles => ([...prevFiles.filter((_, index) => !idxs.includes(index))]));
		},
		(list) => {
			//Update entire list of files
			setFiles(list);
		}

	]
}

export default useFilesState;