import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FileContainer = styled(motion.div)`
	display: none;
	text-align: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
	position: relative;
	white-space: nowrap;
	font-weight: bold;
	width: 0%;
	opacity: 0;
`;

export const FileAttributes = styled.div`
	display: block;
	text-align: left;
	z-index: 10;
`;

export const FileName = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

export const FileSize = styled.div`
	font-size: 0.7rem;
`;

export const RemoveFile = styled.div`
	text-align: right;
	cursor: pointer;
	display: flex;
	justify-content: right;
	align-items: center;
`;

export const RemoveIcon = styled(motion.i)`
	padding: 0.5rem;
`;