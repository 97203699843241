import { utils } from '@champtitles/utilities';

const hiddenInputLabel = {
	variants: css => ({
		dragging: {
			outlineOffset: '-20px',
			outline: `2px dashed ${css.white}`,
			backgroundColor: css.primary,
			transition: {
				duration: 0.2
			}
		},
		notDragging: {
			outlineOffset: '-10px',
			outline: `2px dashed ${css.black}`,
			backgroundColor: css.white,
			transition: {
				duration: 0.2
			}
		}
	})
};

const fileTypeWarning = {
	variants: css => ({
		error: {
			backgroundColor: `rgba(${utils.hexToRgb(css.danger)}, 0.7)`,
			color: css.white,
			transition: {
				duration: 2
			}
		},
		noError: {
			backgroundColor: 'rgba(0,0,0,0)',
			color: css.gray400
		}
	})
};

const fileSizeWarning = {
	variants: css => ({
		error: {
			backgroundColor: `rgba(${utils.hexToRgb(css.danger)}, 0.7)`,
			color: css.white,
			transition: {
				duration: 2
			}
		},
		noError: {
			backgroundColor: 'rgba(0,0,0,0)',
			color: css.gray400
		}
	})
};

export {
	hiddenInputLabel,
	fileTypeWarning,
	fileSizeWarning
};